export function formatZipcodesForSave(zipcodes) {
    let output = [];
    zipcodes.forEach((item) => {
        delete item.highlight;
        output.push(item);
    });
    return output;
}

export function sortZipcodes() {
    this.other_textfields.sort(function (a, b) {
        let cost_a = parseFloat(a.min_order_cost);
        let cost_b = parseFloat(b.min_order_cost);
        if (cost_a < cost_b) {
            return -1;
        }
        if (cost_a > cost_b) {
            return 1;
        }
        return 0;
    });
}